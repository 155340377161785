import React, { useContext } from 'react'
import styled, {css} from 'styled-components'
import { SearchContext } from '.'

const Container = styled.div`
  padding: 7.5px 12.5px;
  display: flex;
  align-items: center;
  z-index: initial;
  background-color: white;

  input:focus {
    outline: none;
  }

  ${({isHero, open}) => isHero
    ? css`
      padding: 20px 20px;
      border-radius: 29.5px;

      @media (min-width: 475px) {
        padding: 19px 30px;
        border-radius: 35px;
      }

      ${open
        ? css`
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
        `
        : css`
          input::placeholder {
            color: #000333;
          }
        `
      }
    `
    : css`
      box-shadow:inset 0px 0px 0px 1px #80808D;
      border-radius: 6px;
    `
  }
`

const Input = styled.input`
  border: 0;
  width: 100%;
  display: block;
  background-color: white !important;
  padding: 0 !important;
  font-weight: ${({isHero}) => isHero ? 700 : 'normal'};

  ${({isHero}) => isHero && css`
    color: #000333;

    @media (min-width: 475px) {
      font-size: 26px;
      line-height: 32px;
    }
  `}
`

const Icon = styled.i`
  content: "&nbsp;";
  font-size: 17px;
  margin-right: ${({isHero}) => isHero ? '10px' : '5px'};
  color: ${({isHero}) => isHero ? '#000333' : '#adb5bd'};
  display: block;

  ${({isHero}) => isHero && css`
    @media (min-width: 600px) {
      font-size: 23px;
    }
  `}
`

export function SearchInput({isHero, ...rest}) {
  const {searchFocused} = useContext(SearchContext)

  return (
    <Container open={searchFocused} isHero={isHero}>
      <Icon className="fa far fa-search" isHero={isHero}/>
      <Input
        isHero={isHero}
        name="q"
        minLength={2}
        aria-label="search"
        type="text"
        {...rest}
      />
    </Container>
  )
}