import styled from "styled-components";
import React from "react";
import {medium} from "../../utils/breakpoints";
import SignupBgDesktop from 'images/signup_bg_desktop.png'
import SignupBgMobile from 'images/signup_bg_mobile.png'
import RoadpassIcon from 'images/icons/roadpass.png'

export const H2Title = styled.h2`
  font-size: 26px;
  margin-bottom: 24px;
  text-align: center;
`

export const SVGInfoIcon = styled.svg`
  margin-right: 8px;
`

export const AlertContainer = styled.div`
 padding: 8px;
 border-radius: 8px;
`

export const SVGDiv = styled.div`
 float: left;
 margin-top: -4px;
`


export const Box = styled.div`
  padding: 10px;
  margin: 2em 0px;
  font-size: 15px;
`

export const InfoMessage = styled.div`
  font-size: 15px;
  margin-left: 25px;
  margin-right: 8px;
 `

export const LoginButton = styled.button`
  padding: 8px 12px;
  gap: 8px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #0076B4;
  border-radius: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #0076B4;
`

export const YellowButton = styled.button`
  padding: 8px 12px;
  gap: 8px;
  width: 100%;
  background: #F9D209;
  border: 1px solid #F9D209;
  border-radius: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #24201D;

  img {
    width: 15px;
    height: 16px;
    margin-right: 8px;
    vertical-align: initial;
  }
`

export const Input = styled.input`
  padding-left: 2.25rem;
`

export const Label = styled.label`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.095em;
  margin-bottom: 4px;
`

export const ForgotYourPassword = styled.a`
  display: block;
  text-align: right;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-decoration-line: underline;
`

export const HRDivider = () => (
  <div style={{width: '100%', padding: '8px 2px 28px'}}>
    <div style={{height: '20px', borderBottom: '1px solid #BDBDBC', textAlign: 'center', margin: '0 auto'}}>
      <span style={{fontSize: '18px', fontWeight: '500', backgroundColor: 'white', padding: '0 10px', color: '#24201D', fontFamily: 'Commissioner, sans-serif'}}>
        or
      </span>
    </div>
  </div>
)

export const SignupBlock = styled.div`
  padding: 24px;
  background: #24201D;
  border-radius: 8px;
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-size: cover;
  background-position: center center;
  background-image: url(${SignupBgDesktop});

  @media (max-width: ${medium}px) {
    background-position: center bottom;
    background-image: url(${SignupBgMobile});
  }
`

export const DivBorderLeft = styled.div`
  border-left: 1px solid gray;

  @media (max-width: ${medium}px) {
    border: none
  }
`

export const DivPaddingRight = styled.div`
  padding-right: 40px;

  @media (max-width: ${medium}px) {
    padding-right: 0px;

    padding-bottom: 40px;
    border-bottom: 1px solid #BDBDBC;
    margin-bottom: 40px;
  }
`

export const DivPaddingLeft = styled.div`
  padding-left: 64px;

  @media (max-width: ${medium}px) {
    padding-left: 0px;
  }
`

export const CampendiumImageBlock = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 14px;
  margin-bottom: 12px;

  img {
    width: 180px;
  }
`

export const RoadpassImageBlock = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 24px;

  img {
    width: 125px;
  }
`

export const SignupContent = styled.p`
  margin: 0 auto;
  text-align: center;
  margin-bottom: 48px;

  a {
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 2px;
  }
`

export const BulletContent = styled.div`
  padding: 0 30px;
  margin-bottom: 24px;

  @media (max-width: ${medium}px) {
    padding: 0 16px;
  }
`

export const IconBullet = styled.img`
  width: 16px;
  height: 14px;
  vertical-align: initial;
  margin-right: 8px;
`

export const Bullet = styled.div`
  margin-bottom: 16px;
  padding: 0 4px;
`

export const FAIcon = styled.i`
  width: 16px;
  margin-right: 8px;
`

export const FAIconBullet = ({ className }) => (
  <FAIcon className={className} />
)

export const RoadpassButton = ({ to, token, title }) => (
  <a href={to}>
    <input name="authenticity_token" type="hidden" value={token} />
    <YellowButton>
      {title}
    </YellowButton>
  </a>
)

export const BannerRowDark = styled.div`
  padding: 24px 48px;
  background: #24201D;
  border-radius: 8px;
  color: white;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 24px 12px;
  display: flex;
  align-items: center;
  width: 98%;

  @media (max-width: ${medium}px) {
    display: block;
    width: 100%;
    margin: 12px 0 24px 0;
    border-radius: 0;
    padding: 32px 16px;
  }
`

export const RoadpassIconWrapper = styled.div`
  @media (max-width: ${medium}px) {
    text-align: center !important;
    margin-bottom: 24px;
  }
`

export const RoadpassIconImg = styled.img`
  height: 48px;
  margin-right: 32px;

  @media (max-width: ${medium}px) {
    margin-right: 0;
  }
`

export const YellowHeading = styled.p`
  color: #FFD500;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0.75rem;

  @media (max-width: ${medium}px) {
    font-size: 18px;
  }
`
