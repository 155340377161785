export default class CampAnalytics {
  // Ideally this would all live in a Stimulus 3.x analytics_controller
  // that would work across the site and could handle targetConnected events,
  // but these methods live here for now since we're still on Stimulus 2.x
  // and have legacy layout pages as well.

  trackUserSignIn(userVars) {
    const event = 'User Signed In'
    this.track(event, { context: 'Roadtrippers' })
    this.identify(userVars)
  }

  trackLegacyUserSignIn(userVars) {
    const event = 'User Signed In'
    this.track(event, { context: 'Campendium Sign In' })
    this.identify(userVars)
  }

  trackAccountCreation(userVars) {
    this.track('Account Created', { context: 'Account Verification Email' })
    this.identify(userVars)
  }

  trackMembershipPurchasePrompted(context) {
    return this.track('Membership Purchase Prompted', { context: (context || null) })
  }

  trackMembershipPurchaseStarted() {
    this.track('Membership Purchase Started', { context: 'Pro Upsell Stripe Module' })
  }

  trackMembershipPurchaseCompleted(props) {
    const event = 'Membership Purchase Completed'
    this.track(event, props)
  }

  trackMembershipTrialStarted(props) {
    const event = 'Membership Trial Started'
    this.track(event, props)
  }

  trackMembershipPurchaseFailed(props) {
    const event = 'Membership Purchase Failed'
    this.track(event, props)
  }

  trackBookingAvailabilitySearched(props) {
    const event = 'Booking Availability Searched'
    this.track(event, props)
  }

  trackBookingAvailabilitySelected(props) {
    const event = 'Booking Availability Selected'
    this.track(event, props)
  }

  trackBookingPurchaseCompleted(props) {
    const event = 'Booking Purchase Completed'
    this.track(event, props)
  }

  trackMap(ctaName) {
    return this.track('CTA Clicked', { context: 'Map', cta_name: ctaName })
  }

  trackSearchResultsMap(ctaName) {
    return this.track('CTA Clicked', { context: 'Search Results Map', cta_name: ctaName })
  }

  trackCampgroundDetails(ctaName, link) {
    return this.track('CTA Clicked', { context: 'Campground Details', cta_name: ctaName }, link)
  }

  trackCTA(context, ctaName, properties, link) {
    return this.track('CTA Clicked', { ...properties, context: context, cta_name: ctaName}, link)
  }

  trackPlaceContentAdded(properties, link) {
    return this.track('Place Content Added', properties, link);
  }

  trackMainMenuLink(link, custom_properties) {
    let properties = {
      menu_section_name: 'Top Nav'
    }
    return this.trackNavLink('Main Menu Clicked', link, properties)
  }

  trackFooterLink(link, custom_properties) {
    let properties = {
      context: 'Footer',
      cta_name: link.textContent,
      destination_url: link.getAttribute('data-menu-item-url') || link.href,
      ...custom_properties
    }

    return this.trackNavLink('Footer Link Clicked', link, properties)
  }

  trackNavLink(event, link, custom_properties) {
    let properties = {
      menu_item_url: link.getAttribute('data-menu-item-url') || link.href,
      menu_item_label: link.getAttribute('data-menu-item-label') || link.textContent,
      menu_item_position: parseInt(link.getAttribute('data-menu-item-position')),
      menu_section_name: link.getAttribute('data-menu-section-name'),
      ...custom_properties
    }

    return this.track(event, properties, link)
  }

  track(event, addedProps, link) {
    if(!window.analytics || window.analytics.typeof === 'undefined') {
      console.warn("analytics service not set up")
      return
    }

    const properties = this.cleanProps({
      // Required properties for Avo
      'brand': 'Campendium',
      'platform': 'Web',
      ...addedProps
    })

    if (link) {
      return window.analytics.trackLink(link, event, properties)
    } else {
      return window.analytics.track(event, properties)
    }
  }

  identify(userVars) {
    if(!window.analytics || window.analytics.typeof === 'undefined') {
      console.warn("analytics service not set up")
      return
    }

    // This will set anonymousId for users not logged in. Not used (yet)
    if(!userVars || !userVars.typeof === 'undefined') {
      window.analytics.identify()
      return
    }

    const userId = userVars.id
    const traits = this.cleanProps({
      email: userVars.email,
      roadpassUserId: userVars.uid
    })

    window.analytics.identify(userId, traits)
  }

  cleanProps(obj) {
    let newObj = {}

    Object.keys(obj).forEach((key) => {
      if ((typeof obj[key] !== 'undefined') && obj[key] !== '') { newObj[key] = obj[key] }
    })

    return newObj
  }
}