import React from 'react'
import createReactClass from 'create-react-class'

var SearchResult = createReactClass({
  handleMouseEnter: function() {
    if(currentPopup !== undefined) {
      currentPopup.remove();
    }
    var renderedData = multiMap.querySourceFeatures('campgrounds');
    var found;
    var campgroundId = this.props.data.id;
    $.each(renderedData, function(i, el) {
      if(el.properties.point_count === undefined && el.properties.id == campgroundId && found === undefined) {
        found = el;
      }
    });
    var coordinates;
    if(found !== undefined) {
      var item = found.properties;
      var bookable = item.booking_url_type !== "Not Bookable";
      this.props.onTrackEvent(item, 'Search Results List')
      coordinates = [item.longitude, item.latitude];
      var contentString = `<a href="/${item.slug}?source=search-results-map" target="_blank"><div class="map-info-bubble">`;
      if (item.sq_img !== null && item.sq_img != 'null') {
        contentString += '<div class="info-bubble-img">' +
          '<img src="' + item.sq_img + '" alt="' + item.title +'">' + '</div>'
        }
        contentString += '<div class="info-bubble-content">'
        if (bookable) {
          contentString +=  '<div class="booking-pill">Bookable</div>'
        }
        contentString += '<p class="info-bubble-title">' + item.title + '</p>' +
          '<p>' + item.city_state.replace(/, $/, '') + '</p>' +
          '<p>' + item.category_title + '</p>' +
          '<p>' +
            '<span class="stars">' +
              '<span class="stars' + item.stars + '"></span>' +
            '</span>' +
          '</p>' +
          '</div>';

        if (item.parking_color === 'red') {
          contentString += `<div class="overnight-parking-alert alert alert-danger" role="alert"><i class="fa-solid fa-octagon-exclamation"></i><div>Overnight parking not permitted</div></div>`;
        } else if (item.parking_color === 'yellow') {
          contentString += `<div class="overnight-parking-alert alert alert-warning" role="alert"><i class="fa-solid fa-triangle-exclamation"></i><div>Overnight parking permissions <br/> unknown</div></div>`;
        }

          contentString += '</div></a>';

        if (bookable) {
          contentString +=
          `<div class="btn-availability-map-wrapper"><a class="btn btn-large btn-warning btn-availability-cta" href="/${item.slug}" target="_blank">Check Availability</a></div>`
        }
    } else {
      contentString = this.props.data.pointCount + ' locations near ' + this.props.data.title;
      coordinates = this.props.data.coordinates;
    }
    currentPopup = new mapboxgl.Popup({ offset: 10 })
      .setLngLat(coordinates)
      .setHTML(contentString)
      .addTo(multiMap);
  },
  handleMouseLeave: function() {
    currentPopup.remove();
  },

  truncatedReview: function() {
    return s(this.props.data.last_review_title).prune(100)._wrapped;
  },

  overnight_rv_parking_warning: function() {
    if (this.props.data.mapicon == "parkingalert") {
      if (this.props.data.parking_color === 'red') {
        return (
          <div className="overnight-parking-alert alert alert-danger" role="alert">
            <i class="fa-solid fa-octagon-exclamation"></i>
            Overnight parking not permitted
          </div>
        )
      } else if (this.props.data.parking_color === 'yellow') {
        return (
          <div className="overnight-parking-alert alert alert-warning" role="alert">
            <i class="fa-solid fa-triangle-exclamation"></i>
            Overnight parking permissions unknown
          </div>
        )
      }
    }
  },

  render: function() {
    if(this.props.data.id === undefined || this.props.data.id === null) {
      return(<div></div>);
    } else {
      const tokenElement = document.getElementsByName('csrf-token')[0];
      const token = tokenElement ? tokenElement.getAttribute('content') : '';
      return (
        <>
          <a href={"/" + this.props.data.slug + "?source=search-results-list"} className="js-clickable" target="_blank">
            <div className="campground-row" id={'campground-row-' + this.props.data.id}>
              <div id={'search-result-adthrive-' + this.props.result_index.index}></div>
              <div className="campground-row-wrapper" onMouseEnter={this.handleMouseEnter}
                   onMouseLeave={this.handleMouseLeave}>
                <a className="campground-image" href={"/" + this.props.data.slug + "?source=search-results-list"} target="_blank">
                  <img alt={this.props.data.title}
                    src={(this.props.data.sq_img === null || this.props.data.sq_img === 'null') ? '/placeholder.png' : this.props.data.sq_img}/>
                </a>
                <div className='campground-details'>
                  {this.props.data.booking_url_type !== "Not Bookable" &&
                    <div className="booking-pill">Bookable</div>
                  }
                  <h4>
                    <a className="campground-title" href={"/" + this.props.data.slug + "?source=search-results-list"} target="_blank">
                      {this.props.data.title}
                    </a>
                  </h4>
                  <span className="campground-location">
                {this.props.data.city_state.replace(/, $/, '')}
              </span>
                  <span className="stars">
                <span className={"stars" + this.props.data.stars}/>
              </span>
                  <small>
                    {this.props.data.reviews_count}
                    {this.props.data.reviews_count == 1
                      ? ' Review'
                      : ' Reviews'
                    }
                  </small>
                  <div className="campground-kind">
                    {this.props.data.offer_price
                      ? this.props.data.offer_price
                      : this.props.data.category_title
                    }
                  </div>
                  {this.props.data.last_review_title && this.props.data.last_review_title != 'null' &&
                    <div className="last-review">
                      <em>"{this.truncatedReview()}"</em><span> - {this.props.data.last_review_author}</span></div>
                  }

                  {this.overnight_rv_parking_warning()}
                </div>

                {this.props.data.booking_url_type !== "Not Bookable" &&
                  <div className="btn-availability-wrapper"><a
                    className="btn btn-large btn-warning btn-availability-cta" href={"/" + this.props.data.slug} target="_blank">Check
                    Availability</a></div>}
                {this.props.data.last_nightly_rate !== undefined && this.props.data.last_nightly_rate !== null && this.props.data.last_nightly_rate != 'null' && this.props.data.booking_url_type === "Not Bookable" &&
                  <div className="campground-last-price">
                    <h3>${this.props.data.last_nightly_rate}</h3>
                    <p className="reported-on">Reported on {this.props.data.last_review_date}</p>
                  </div>
                }
              </div>
            </div>
          </a>
        </>
      );
    }
  }
});

export default SearchResult