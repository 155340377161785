import React, { useEffect, useRef, useState, useMemo } from 'react'
import styled from 'styled-components'
import { SearchInput } from './SearchInput'
import { Results } from './Results/Results'
import debounce from 'lodash.debounce'
import {processSearchResult, urlWithRecentPageVisitParam} from "./processSearchResult"
import {iconClasses} from "./iconClasses"

const OuterContainer = styled.div`
  * {
    box-sizing: border-box;
  }
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #3B3F5B;
  position: relative;
  z-index: ${({zIndex}) => zIndex};
  filter: ${({isHero}) => isHero && 'drop-shadow(0px 0px 10px rgba(59, 63, 91, 0.2))'};
`

export const SearchContext = React.createContext({})
const sanitizeSearchTerm = term => term.trim().substr(0, 100).replace(/\s{2,}/g, ' ')

function SearchField({ searchId, placeholder, zIndex, isHero = false, autoComplete, rootUrl, context }) {
  const [searchFocused, setSearchFocused] = useState(false)
  const [searchResults, setSearchResults] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [abortPriorSearch, setAbortPriorSearch] = useState(null)
  const containerRef = useRef(null)
  const baseUrl = rootUrl || '/'

  useEffect(() => {
    const clickHandler = (e) => {
      const ref = containerRef.current
      containerRef.current && !ref.contains(e.target) && setSearchFocused(false)
    }

    const keyHandler = (e) => {
      if (e.keyCode === 9 || e.keyCode === 27) {
        setSearchFocused(false)
      }
    }

    document.addEventListener("click", clickHandler)
    document.addEventListener("keyup", keyHandler)

    return () => {
      document.removeEventListener("click", clickHandler)
      document.removeEventListener("keyup", keyHandler)
    }
  }, [])

  // Generate indexed search results so that they can be consistently referenced by these generated indexes
  const {indexedSearchResults, searchResultsByIndex, maxResultIndex} = useMemo(() => {
    const byCategory = []
    const byIndex = {}
    let resultIndex = 0

    searchResults && searchResults.forEach(category => {
      const indexedResults = []
      category.results.forEach(result =>
        processSearchResult(result, category, context)
          .forEach(processedResult => {
            const index = resultIndex++
            indexedResults.push({index, result: processedResult})
            byIndex[index] = processedResult
          })
      )
      byCategory.push({...category, results: indexedResults})
    })

    return {
      indexedSearchResults: byCategory,
      searchResultsByIndex: byIndex,
      maxResultIndex: resultIndex - 1,
    }
  }, [searchResults])

  const runSearch = useMemo(
    () => debounce(async (searchTerm) => {
      const controller = new AbortController()
      const {signal} = controller
      // This syntax looks funny, but remember that when a useState setter is passed a function it gives the current value as the first argument
      // We want to set the value to be a function that can be used to abort the search - so we get two layers of anonymous functions
      setAbortPriorSearch(() => () => controller.abort())

      const query = new URLSearchParams({ [searchId]: searchTerm })
      try {
        const response = await fetch(baseUrl + 'search.json?' + query, {signal})
        const results = await response.json()

        results.some(x => !!x) ? setSearchResults(results) : setSearchResults(null)
        setSelectedIndex(null)
      } catch (e) {
        if (e.name !== "AbortError") {
          throw e
        }
      }
    }, 500),
    [setSearchResults, setSelectedIndex]
  )

  const handleInputChange = (e) => {
    const searchTerm = e.target.value
    const sanitizedSearchTerm = sanitizeSearchTerm(searchTerm)

    setSearchFocused(true)
    setSearchTerm(searchTerm)

    // If a prior search request is in flight, it should be canceled to avoid race conditions
    if (abortPriorSearch) abortPriorSearch()

    if (sanitizedSearchTerm === '') {
      setSearchResults(null)
    } else {
      runSearch(sanitizedSearchTerm)
    }
  }

  const handleKeyPress = (e) => {
    switch(e.keyCode) {
      case 38: // up
        e.preventDefault()
        if (selectedIndex === null) {
          setSelectedIndex(0)
        } else if (selectedIndex !== 0) {
          setSelectedIndex(selectedIndex - 1)
        }
        break
      case 40: // down
        e.preventDefault()
        if (selectedIndex === null) {
          setSelectedIndex(0)
        } else if (selectedIndex !== maxResultIndex) {
          setSelectedIndex(selectedIndex + 1)
        }
        break
      case 13: // enter
        e.preventDefault()
        window.location = selectedIndex === null
          ? urlWithRecentPageVisitParam({
            url: `${baseUrl}campgrounds/map?q=${encodeURIComponent(sanitizeSearchTerm(searchTerm))}`,
            title: sanitizeSearchTerm(searchTerm),
            iconClass: iconClasses.search,
          }, context)
          : searchResultsByIndex[selectedIndex].url
        break
    }
  }

  const contextValue = useMemo(
    () => ({
      safeSearchTerm: sanitizeSearchTerm(searchTerm),
      searchFocused,
      selectedIndex,
      indexedSearchResults,
      setSelectedIndex
    }),
    [searchTerm, searchFocused, selectedIndex, indexedSearchResults, setSelectedIndex]
  )

  return (
    <SearchContext.Provider value={contextValue}>
      <OuterContainer ref={containerRef} zIndex={zIndex || 13} isHero={isHero}>
        <SearchInput
          isHero={isHero}
          onKeyDown={handleKeyPress}
          onFocus={() => setSearchFocused(true)}
          placeholder={placeholder}
          onChange={handleInputChange}
          value={searchTerm}
          onClick={() => setSearchFocused(true)}
          autoComplete={autoComplete}
        />
        {searchFocused && <Results isHero={isHero}/>}
      </OuterContainer>
    </SearchContext.Provider>
  )
}

export default SearchField
