import React, {useEffect, useState} from 'react'
import ProLogo from 'images/roadpass_staggered_logo.png'

import {
  H2Title,
  LoginButton,
  Input,
  Label,
  ForgotYourPassword,
  HRDivider,
  Box,
  DivPaddingRight,
  DivPaddingLeft,
  RoadpassButton,
  SVGInfoIcon,
  InfoMessage,
  AlertContainer,
  SVGDiv,
  BannerRowDark,
  RoadpassIconImg,
  YellowHeading,
  RoadpassIconWrapper,
} from './components'


const SessionsNew = ({
  roadtrippersAuthorizePath,
  roadtrippersRegisterPath,
  forgotPasswordPath,
  signInPath,
  showLoginByDefault,
  defaultEmail,
}) => {
  const [showLogin, setShowLogin] = useState(showLoginByDefault)
  const [token, setToken] = useState('')

  const handleLoginClick = () => {
    setShowLogin(true)
  }

  const checkRedirectParams = () => {
    // checks for redirect_after params to display banner

    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    return urlParams.get('redirect_after');
  }

  useEffect(() => {
    setToken(
      document.getElementsByName('csrf-token')[0].getAttribute('content')
    )
  }, [])

  return (
    <div className="row">
      {(checkRedirectParams()) && (
        <BannerRowDark>
          <RoadpassIconWrapper>
            <RoadpassIconImg src={ProLogo} />
          </RoadpassIconWrapper>
          <div>
            <YellowHeading>You are one step closer to your Roadpass Pro membership.</YellowHeading>
            <p className="mb-0">Login if you are a Roadpass member or Sign Up and continue to your <b>Free 7 Day Trial Membership</b> of Roadpass Pro.</p>
          </div>
        </BannerRowDark>
      )}

      <div className="col-md-12">
        <h1 style={{ fontSize: "28px", textAlign: "center", marginBottom: "50px" }}>Log in or sign up to leave reviews, save campgrounds, and use premium features</h1>
      </div>

      <div className="col-md-6">
        <DivPaddingRight>
          <H2Title>Log In</H2Title>
          <div>
            <AlertContainer className="alert alert-info">
              <div>
                <SVGDiv>
                  <SVGInfoIcon width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.06836 14.1436C10.9443 14.1436 14.1436 10.9443 14.1436 7.0752C14.1436 3.19922 10.9375 0 7.06152 0C3.19238 0 0 3.19922 0 7.0752C0 10.9443 3.19922 14.1436 7.06836 14.1436ZM7.06836 12.7285C3.93066 12.7285 1.42188 10.2061 1.42188 7.0752C1.42188 3.9375 3.93066 1.41504 7.06152 1.41504C10.1992 1.41504 12.7217 3.9375 12.7285 7.0752C12.7354 10.2061 10.2061 12.7285 7.06836 12.7285ZM7.02734 4.75781C7.55371 4.75781 7.96387 4.33398 7.96387 3.82129C7.96387 3.29492 7.55371 2.87793 7.02734 2.87793C6.51465 2.87793 6.09766 3.29492 6.09766 3.82129C6.09766 4.33398 6.51465 4.75781 7.02734 4.75781ZM5.8584 10.7871H8.63379C8.94141 10.7871 9.18066 10.5615 9.18066 10.2539C9.18066 9.95996 8.94141 9.72754 8.63379 9.72754H7.875V6.50098C7.875 6.09082 7.66992 5.81738 7.28711 5.81738H5.98145C5.67383 5.81738 5.43457 6.0498 5.43457 6.34375C5.43457 6.6582 5.67383 6.87695 5.98145 6.87695H6.67871V9.72754H5.8584C5.55762 9.72754 5.31152 9.95996 5.31152 10.2539C5.31152 10.5615 5.55762 10.7871 5.8584 10.7871Z" fill="#037EAE"/>
                  </SVGInfoIcon>
                </SVGDiv>
                <InfoMessage>
                  Your Roadpass account is now a Roadtrippers account. Log in with Roadtrippers using your Roadpass username and password.
                </InfoMessage>
              </div>
            </AlertContainer>
            <RoadpassButton to={roadtrippersAuthorizePath} title="Log In with Roadtrippers" token={token} />
            <HRDivider />
            {showLogin && (
              <form action={signInPath} className="mb-3" method="post">
                <input name="authenticity_token" type="hidden" value={token} />
                <div className="row">
                  <Label htmlFor="">Email</Label>
                  <div className="d-flex">
                    <i className="fal fa-envelope input-icon" />
                    <Input
                      defaultValue={defaultEmail || ''}
                      name="user[email]"
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <Label htmlFor="">Password</Label>
                  <div className="d-flex">
                    <i className="fal fa-lock input-icon" />
                    <Input
                      name="user[password]"
                      type="password"
                      className="form-control"
                    />
                  </div>
                </div>
                <ForgotYourPassword href={forgotPasswordPath}>Forgot password?</ForgotYourPassword>
                <LoginButton type="submit" className="mt-3">Log In with Email</LoginButton>
              </form>
            )}
            {!showLogin && (
              <div>
                <LoginButton onClick={handleLoginClick}>Log In with Email</LoginButton>
              </div>
            )}
          </div>
        </DivPaddingRight>
      </div>

      <div className="col-md-6">
        <DivPaddingLeft>
          <H2Title>Sign Up</H2Title>
        </DivPaddingLeft>
        <Box>
          Don't have a Campendium account yet? Create your Roadtrippers account and get access to Campendium's community.
        </Box>
        <RoadpassButton to={roadtrippersRegisterPath} title="Sign Up with Roadtrippers" token={token} />
      </div>
    </div>
  )
}

export default SessionsNew