import { property } from 'lodash';
import React, { Component } from 'react'
import Select from 'react-select'

const renderList = (max) => {
  // populate list with values 1 - max

  let content = [];
  for (let i = 1; i <= max; i++) {
    content.push({ value: `${i}`, label: `${i}` });
  }
  return content;
}

const renderEquipmentList = () => {
  // populate with equipment list values

  const content = [
    { value: 'Travel Trailer', label: 'Travel Trailer' },
    { value: 'Bus', label: 'Bus' },
    { value: 'Fifth Wheel', label: 'Fifth Wheel' },
    { value: 'Motorhome', label: 'Motorhome' },
    { value: 'Pop-Up Trailer', label: 'Pop-Up Trailer' },
    { value: 'Truck Camper', label: 'Truck Camper' },
    { value: 'Van', label: 'Van' }
  ]
  return content;
}

const DropDown = props => {  

  return (
    <Select
      {...props} 
      className = {props.class}
      id = {props.id}
      name = {props.name}
      placeholder = {props.placeholder}
      defaultValue = {JSON.parse(props.default)}
      options = {props.id === 'availability_form_number_of_guests' ? renderList(props.max) : renderEquipmentList()}
      escapeClearsValue = "false"
      classNamePrefix = "select-dropdown"
    />
  );
}

export default DropDown
