import {iconClasses} from "./iconClasses";

function buildUrlSafeBase64JsonString(obj) {
  const encoded = Object.entries(obj).reduce((carry, [key, value]) => {
    carry[key] = encodeURIComponent(value)
    return carry
  }, {})

  return btoa(JSON.stringify(encoded))
}

function getIcon(result, category) {
  const {icon} = result;
  const {title} = category;
  let iconClass = iconClasses.map;

  if (icon && iconClass.hasOwnProperty(icon)) {
    iconClass = iconClass[icon]
  }
  if (title === 'Cities') {
    iconClass = iconClasses.building
  }

  return iconClass
}

export function urlWithRecentPageVisitParam(result, context) {
  const {url, title, iconClass} = result

  if (!title) {
    return result.url
  }

  const rpv = buildUrlSafeBase64JsonString({
    title,
    icon_class: iconClass,
  })

  const parsedUrl = new URL(url, window.location.origin)
  parsedUrl.searchParams.append('rpv', rpv)
  parsedUrl.searchParams.append('context', context)

  return parsedUrl.href
}

export function processSearchResult(searchResult, category, context = 'primary-search') {
  const output = []

  if (searchResult.landing_page_enabled) {
    output.push({
      ...searchResult,
      url: `${searchResult.url}/map`,
      type: 'map',
      iconClass: iconClasses.map,
    })
    output.push({
      ...searchResult,
      type: 'info',
      iconClass: iconClasses.infoCircle,
    })
  } else {
    output.push({
      ...searchResult,
      iconClass: getIcon(searchResult, category),
    })
  }

  output.map(item => item.url = urlWithRecentPageVisitParam(item, context))

  return output
}