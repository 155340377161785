export const iconClasses = {
  caravan: 'fak fa-rv-park',
  poop: 'fak fa-dump',
  parking: 'fak fa-parking',
  trees: 'fak fa-trees',
  mountains: 'fa fa-mountains',
  building: 'fa fa-building',
  infoCircle: 'fa fa-info-circle',
  map: 'fa fa-map',
  search: 'fa fa-search'
}