import React, {useContext, useMemo} from 'react';
import styled from 'styled-components'
import {SearchContext} from '..';
import {Icon} from './Icon'
import escapeStringRegexp from "escape-string-regexp";
import {rInvert} from 'ranges-invert'

const InnerResult = styled.div`
  display: flex;
  span:first-of-type {
    &:hover {
      background-color: #F0F0F0;
    }
  }
`

const SingleResult = styled.a`
  padding: 7px 18px;
  display: block;
  color: #000333;
  background-color: ${({selected}) => selected && '#E6E9EF'};
  &:hover {
    text-decoration: none;
    color: #000333;
  }
`

const TypeTag = styled.span`
  margin-left: 9px;
  background-color: #F5F5F5;
  color: #3B3F5B;
  padding: 3px 6px;
  border-radius: 3px;
  text-decoration: none !important;
  font-weight: 900;
  font-size: 9px;
  line-height: 15px;
  letter-spacing: 0.115em;
  text-transform: uppercase;
  vertical-align: .125rem;
`

const ResultText = ({text}) => {
  const {safeSearchTerm} = useContext(SearchContext)

  const textSections = useMemo(() => {
    const length = safeSearchTerm.length;
    const matchRanges = Array.from(text.matchAll(new RegExp(escapeStringRegexp(safeSearchTerm), 'gi')))
      .map(({index}) => [index, index + length])
    const boldRanges = rInvert(matchRanges, text.length)

    const output = {}
    matchRanges.forEach((range) => output[range[0]] = {text: text.slice(range[0], range[1]), bold: false})
    boldRanges.forEach((range) => output[range[0]] = {text: text.slice(range[0], range[1]), bold: true})

    return Object.keys(output).map(k => output[k])
  }, [text, safeSearchTerm])

  return <div>
    {textSections.map(({text, bold}, i) =>
      bold
        ? <strong key={i}>{text}</strong>
        : <span key={i}>{text}</span>
    )}
  </div>
}

export function Result({result, index}) {
  const {selectedIndex, setSelectedIndex} = useContext(SearchContext)
  const selected = useMemo(
    () => index === selectedIndex,
    [index, selectedIndex]
  )

  const {url, type, title, iconClass} = result

  let fullUrl;
  if (type === 'campground') {
    fullUrl = `${url}&source=autocomplete-search`;
  } else {
    fullUrl = url;
  }

  return (
    <SingleResult
      selected={selected}
      onMouseEnter={() => setSelectedIndex(index)}
      href={fullUrl}
      tabIndex={-1}
    >
      <InnerResult>
        <Icon className={iconClass}/>
        {type ?
          <>
            <ResultText text={title}/>
            <TypeTag>{type}</TypeTag>
          </>
          :
          <ResultText text={title}/>}
      </InnerResult>
    </SingleResult>
  )
}