import React, {useState, useContext} from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import { Result } from './Result'
import { SearchContext } from '..'

const CategoryTitle = styled.span`
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: #676777;
  margin-left: 11px;
  margin-top: 20px;
  display: block;
`

const Container = styled.div`
  position: absolute;
  color: #3B3F5B;
  top: calc(100% - 9px);
  background: white;
  border-radius: 6px;
  width: 100%;
  /* @media (min-width: 1280px) {
    min-width: 344px;
  } */
  /* min-width: 344px; */
  left: 0;
  padding: 16px 0 10px 0;
  box-sizing: border-box;
  z-index: -1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

const HeroContainer = styled(Container)`
`

const DefaultContainer = styled(Container)`
  border: 1px solid #80808D;
`

const GeoLocateLink = styled.a`
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.095em;
  text-transform: uppercase;
  color: #3B3F5B;
  &:hover {
    text-decoration: none;
    color: #3B3F5B;
  }
`

const EmptyResults = () => 
  <>
    <div style={{ padding: '0 18px 0 18px' }}>
      Start typing...<br />
      - City or State<br />
      - National Park<br />
      - National Forest<br />
      - State Park<br />
      - Campground Name<br />
    </div>
  </>

function ResultsList() {
  const {indexedSearchResults} = useContext(SearchContext)

  return (
    indexedSearchResults.map(({title, results}, i) => (
      <div key={i}>
        <CategoryTitle style={ i === 0 ? { marginTop: '10px' } : {} } >{title}</CategoryTitle>
        { i !== 0 && <hr style={{ borderTopColor: '#dee2e6', marginTop: '9px', marginBottom: 0 }}/> }
        <ul style={{paddingLeft: 0, marginBottom: 0}}>
          {results.map(({result, index}) =>
            <Result key={index} index={index} result={result} />
          )}
        </ul>
      </div>
    ))
  )
}

export function Results({isHero}) {
  const {indexedSearchResults} = useContext(SearchContext)
  const [geoLocating, setGeoLocating] = useState(false)

  const geoLocate = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setGeoLocating(true)
      window.location = `/campgrounds/map?q=&latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&context=near-me-search`
    }, (_err) => {
      alert('Sorry, we could not geolocate you.')
    })
  }

  const Container = isHero ? HeroContainer : DefaultContainer

  return (
    <Container className="search-results-container">
      <div>
        {indexedSearchResults.length > 0
          ? <ResultsList/>
          : <EmptyResults />
        }
      </div>
      <hr style={{ borderTopColor: '#dee2e6', margin: '9px 0' }}/>
      <div style={{ padding: '0 18px 0 18px' }}>
        {geoLocating === false
          ? (
            <GeoLocateLink href="#" onClick={geoLocate} tabIndex={-1}>
              <Icon className="fa fa-location-arrow" style={{ marginLeft: '2px', marginRight: '7px' }} />
              Use My Location
            </GeoLocateLink>
          ) : (
            'Searching...'
          )
        }
      </div>
    </Container>
  )
}