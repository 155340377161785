import React from 'react'
import createReactClass from 'create-react-class'
import SearchResult from 'components/SearchResult'
import ClusterResult from 'components/ClusterResult'
import qs from 'qs'

window.multiMap = undefined;
window.windowHeight = undefined;
window.currentPopup = undefined;
window.geojsonCenter = undefined;
window.markers = {};
window.allCampgrounds = { 'type': 'FeatureCollection', "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } }, 'features': [] };
window.favoriteCampgrounds = { 'type': 'FeatureCollection', "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } }, 'features': [] };
window.sortMap = {};
window.userFavorites = {};

const calcCommonNavHeight = () => {
  return $('#main-header').height()
}

const segmentPlaceDiscoveryProperties = (results) => {
  const cleanData = $("#campground_search :input")
    .filter(
      function(index, element) {
        return $(element).attr('name') != 'utf8' && $(element).attr('name') != 'exclude_closed_temporarily_mobile' && $(element).val() != '';
      }
    )
    .serialize();

  const objectData = qs.parse(cleanData);
  const searchFilterNames = []
  const filters = ['hookup', 'price', 'cell', 'access', 'policy', 'discount_clubs', 'recreation', 'elevation', 'admin_only', 'facilities', 'category_id']
  filters.forEach(filter => {
    if (objectData['filter_by_' + filter] || objectData[filter + '_range']) {
      if (filter === 'category_id') {
        searchFilterNames.push('Categories')
      } else {
        const txt = filter.charAt(0).toUpperCase() + filter.substr(1).toLowerCase();
        searchFilterNames.push(txt);
      }
    }
  })

  const realPrimaryCategoryIds = ['11', '25', '2', '9', 'PL'];
  const primaryCategoryIds = objectData.filter_by_category_id ? objectData.filter_by_category_id.filter(id => realPrimaryCategoryIds.includes(id)) : []
  const secondaryCategoryIds = objectData.filter_by_category_id ? objectData.filter_by_category_id.filter(id => !realPrimaryCategoryIds.includes(id)) : []
  const primaryCategoryNamesObject = [{
    title: "Public",
    className: "public",
    value: "PL"
  },
    {
      title: "RV Park",
      className: "rv",
      value: "2"
    },
    {
      title: "Parking",
      className: "parking",
      value: "25"
    },
    {
      title: "Dump",
      className: "dump",
      value: "9"
    }
  ]
  const primaryCategoryNames = primaryCategoryNamesObject.filter(category => primaryCategoryIds.includes(category.value)).map(category => category.title)
  const secondaryCategoryNames = window.allCategories.filter(category => secondaryCategoryIds.includes(category.value.toString())).map(category => category.title)

  return {
    ...window.searchDiscoveryProperties,
    search_results_count: results.length,
    search_primary_category_names: primaryCategoryNames,
    search_secondary_category_names: secondaryCategoryNames,
    search_filter_names: searchFilterNames,
    search_primary_category_ids: primaryCategoryIds,
    search_secondary_category_ids: secondaryCategoryIds,
  }
}

window.redrawMapDimensions = function() {
  if($(window).width() < 768) {
    var mapVisibility = $('#multi-map').is(':visible');
    $('div.list').width('');
    $('div#search-filters').width('').css({ top: calcCommonNavHeight() }).show();
    $('body.campgrounds-index div.list #top-spacer').height('25px');
    $('#map-wrapper').width('100%').css({ top: calcCommonNavHeight() });
    $('#multi-map').height($(window).height() - calcCommonNavHeight()).width('100%');
    $('div.with-expansion .checkboxes').removeClass('hidden').hide();
    $('div.filter-more div.apply-buttons').css({ top: 0 });
    if($('#display-toggle').hasClass('with-listings')) {
      $('div#search-results,div#carousel').show();
      $('#map-wrapper').hide();
    } else {
      $('div#search-results,div#carousel').hide();
      $('#map-wrapper').show();
    }
    $('div.filter-more').width($('div.list').width()).height($(window).height() - (calcCommonNavHeight()));
  } else {
    $('div.with-expansion .checkboxes').removeClass('hidden').css({ display: 'flex' });
    $('div.with-expansion .checkboxes + .checkboxes').addClass('hidden').show();
    $('div.with-expansion span.show-more, div.with-expansion span.hide-more').removeClass('hidden');
    $('div.list,div#search-filters').css({ width: $(window).width()/2});
    $('div.list').css({ 'margin-bottom': '50px' });
    var mapWidth = $('div.wide-container').width() - $('div.list').width() - 1;
    //force rebuild
    $('div.filter-more div.apply-buttons').css({ top: calcCommonNavHeight() + 85 });
    $('div.filter-more').css({ paddingTop: 84 });
    $('body.campgrounds-index div.list #top-spacer').height(calcCommonNavHeight() + 150);
    $('body.campgrounds-index div#carousel-and-filters #search-filters').css({ top: calcCommonNavHeight() });
    $('#map-wrapper').show().width(mapWidth).css({ top: calcCommonNavHeight() });
    $('#multi-map').height($(window).height() - calcCommonNavHeight()).width(mapWidth);
    $('div.filter-more').width($('div.list').width()).height($(window).height() - (calcCommonNavHeight())).css({ top: 42 });
    $('div.category_id').css({ paddingBottom: calcCommonNavHeight() });
  }

  $('div.filter-more div.apply-buttons').css({ width: $('div.list').width()});

  if(/(android)/i.test(navigator.userAgent)) {
    $('div.buttons a').addClass('mobile');
  }
  if(navigator.userAgent.match(/iPad/i) != null || navigator.userAgent.match(/iPhone/i) != null) {
    $('div.buttons a').addClass('mobile');
    $('div.list').css({ 'overflow-y': 'scroll', 'webkit-overflow-scrolling': 'touch', height: $(window).height() }); if(window.orientation == 90) {
      $('.slick-arrow').css({ top: '29px' });
    } else if(window.orientation == 0) {
      $('.slick-arrow').css({ top: '13px' });
    }
  } else {
    $('.slick-prev,.slick-next').css({ top: $('.slick-slide:first').width()/2 - $('.slick-prev').height()/2 });
  }
};

var handleTrackEvent = function(data, context) {
  const isPresent = str => !!str && str !== 'null' && str !== '{}';
  var country = data.country;
  if (country === 'Canada') {
    country = 'CA';
  } else if (country === 'Mexico') {
    country = 'MX';
  }

  const properties = {
    place_name: data.title,
    place_city: data.city,
    place_country: country,
    place_region: data.state,
    place_price_tier: undefined,
    place_category_names: data.category_title,
    campendium_campground_id: data.id.toString(),
    booking_type: data.booking_url_type,
    context: context,
  }

  if (isPresent(data.place_id)) {
    properties['place_id'] = data.place_id.toString();
  }

  if (isPresent(data.is_extraordinary_place)) {
    properties['is_extraordinary_place'] = data.is_extraordinary_place;
  }

  window.campAnalytics.track('Place Selected', properties)
}

var Search = createReactClass({
  getInitialState: function() {
    var secondaryTitle = (this.props.secondaryTitle == null || this.props.secondaryTitle === undefined) ? '' : this.props.secondaryTitle;
    return { metaTitle: secondaryTitle, campgrounds: [], clusters: [], campgroundIds: 'unset', noResultsClass: 'hide', timesLoaded: 0, campgroundCount: 0 }
  },

  setMapDimensions: function() {
    redrawMapDimensions();
  },
  setPriceSliders: function(values) {
    document.getElementById('price-slider').noUiSlider.set(values);
    document.getElementById('price-slider-mobile').noUiSlider.set(values);
  },
  setElevationSlider: function(values) {
    document.getElementById('elevation-slider').noUiSlider.set(values);
  },
  updateParamsFromHash: function() {
    // Update params from hash
    $('#campground_search .dynamic').remove();
    $('#search-filters a.selected').removeClass('selected');
    $('#search-filters span.selected').removeClass('selected');

    $("input[name='order_by']").val('');
    $('.dropdown.order_by > a span:first').html('Sort By');

    if ($.cookie('persist_exclude_closed_temporarily')) {
      $('input[name="exclude_closed_temporarily"]').prop('checked', true);

      this.fetchResults(false);
    }

    if($.cookie('persist_free') === undefined && !document.location.hash.match(/price-range=0-0/)) {
      $('input[name="price_range"]').val('0-200');
      $('span.pricing').html('$0 - $200');
      this.setPriceSliders([0,200]);
    } else {
      $('input[name="price_range"]').val('0-0');
      $('span.pricing').html('FREE');
      $('.filter-price span.label').hide();
      $('.dropdown.price > a').addClass('selected');
      this.setPriceSliders([0,0]);
      $('.persist-free').addClass('selected');
      $('#price-slider,#price-slider-mobile').attr('disabled', true);
      if(document.location.hash == '') {
        document.location.hash = 'price-range=0-0';
      } else if(!document.location.hash.match(/price-range/)) {
        document.location.hash = document.location.hash + '&price-range=0-0';
      }
   }

    if (location.pathname == '/' + $('input[name=state]').val() + '/rv-parks' || location.pathname == '/' + $('input[name=state]').val() + '/rv-parks') {
      $('.filter-category a.rv').addClass('selected');
      $('input[name=category]').val('rv-parks');
      // add checked true to child input
    } else if (location.pathname == '/' + $('input[name=state]').val() + '/state-parks' || location.pathname == '/' + $('input[name=state]').val() + '/state-parks') {
      $('input[name=category]').val('state-parks');
      $('div.category_id a[data-value="1"]').addClass('selected');
    } else if (location.pathname == '/' + $('input[name=state]').val() + '/dump-stations' || location.pathname == '/' + $('input[name=state]').val() + '/dump-stations') {
      $('.filter-category a.dump').addClass('selected');
      $('input[name=category]').val('dump-stations');
    } else {
      $('input[name=category]').val('');
    }
    if(location.pathname.includes('free-camping')) {
      $('input[name="price_range"]').val('0-0');
      $('span.pricing').html('$0 - $0');
      this.setPriceSliders([0,0]);
      $('.dropdown.price > a').addClass('selected');
    }

    $('.permissions-unknown').removeClass('toggle-true').addClass('toggle-false');
    $('.not-permitted').removeClass('toggle-true').addClass('toggle-false');
    $('input.parking-color-yellow').val('');
    $('input.parking-color-red').val('');

    console.log('location.hash', location.hash);
    $.each(location.hash.replace(/^#/, '').split('&'), function(i, param) {
      var paramDetail = decodeURIComponent(param).split('=');
      if(paramDetail[0] == 'order_by') {
        $('input[name=order_by]').val(paramDetail[1]);
        var $link = $(".filter-order_by a[data-value='" + paramDetail[1] + "']");
         $('.dropdown.order_by > a span:first').html($link.attr('data-selected'));
        $('.dropdown.order_by > a').addClass('selected');
        $link.toggleClass('selected');
      } else if(paramDetail[0] == 'elevation_range') {
        var sliderMinValue = paramDetail[1].split('-')[0];
        var sliderMaxValue = paramDetail[1].split('-')[1];
        $('input[name="elevation_range"]').val(paramDetail[1]);
        $('span.elevation').html(sliderMinValue + ' - ' + sliderMaxValue);
        this.setElevationSlider([sliderMinValue, sliderMaxValue]);
      } else if(paramDetail[0] == 'filter_by_category_id[]') {
        $.each(paramDetail[1].split(','), function(j, category_id) {
          $(".filter-category a[data-value='" + category_id + "']").addClass('selected');
          $(".filter-category a[data-value='" + category_id + "'] .checkbox").addClass('selected');
          $(".category_id a[data-value='" + category_id + "']").addClass('selected');
        });
        this.updateCategoryIds();
      } else if(paramDetail[0] == 'filter_by_hookup[]' || paramDetail[0] == 'filter_by_cell[]' || paramDetail[0] == 'filter_by_policy[]' || paramDetail[0] == 'filter_by_discount_clubs[]' || paramDetail[0] == 'filter_by_recreation[]' || paramDetail[0] == 'filter_by_facilities[]') {
        // Set input value
        var className = paramDetail[0].replace(/filter_by_/, '').replace(/\[\]/, '');
        $('#campground_search').prepend($('<input>').attr('class', 'input-' + className + ' dynamic').attr('name', 'filter_by_' + className + '[]').attr('type', 'hidden').val(paramDetail[1]));
        // Check box
        var $div = $('.' + paramDetail[0].replace(/filter_by_/, '').replace(/\[\]/, ''));
        $.each(paramDetail[1].split(','), function(j, value) {
          $div.find("a[data-value='" + value + "']").addClass('selected');
        });
      } else if(paramDetail[0] == 'price_range') {
        var sliderMinValue = paramDetail[1].split('-')[0];
        var sliderMaxValue = paramDetail[1].split('-')[1];
        $('input[name="price_range"]').val(paramDetail[1]);
        $('span.pricing').html('$' + sliderMinValue + ' - $' + sliderMaxValue);
        this.setPriceSliders([sliderMinValue, sliderMaxValue]);
        $('.dropdown.price > a').toggleClass('selected', sliderMinValue != 0 || sliderMaxValue != 200);
      } else if(paramDetail[0] == 'bounds') {
        $('input#bounds').val(paramDetail[1].replace(/\+/g, ' '));
        if(multiMap !== undefined) {
          var existingBounds = eval($('input#bounds').val().replace(/\(/g, '[').replace(/\)/g, ']'));
          var filteredBounds = [
            [existingBounds[0][1], existingBounds[0][0]],
            [existingBounds[1][1], existingBounds[1][0]]
          ];
          multiMap.fitBounds(filteredBounds);
        }
      } else if (paramDetail[0] === 'filter_by_parking_colors' && paramDetail[1] === 'yellow') {
        $('input.parking-color-yellow').val('yellow');
        $('.permissions-unknown').removeClass('toggle-false').addClass('toggle-true');
      } else if (paramDetail[0] === 'filter_by_parking_colors' && paramDetail[1] === 'red') {
        $('input.parking-color-red').val('red');
        $('.not-permitted').removeClass('toggle-false').addClass('toggle-true');
      }
    }.bind(this));

    $('.dropdown.category > a').toggleClass('selected', $('.filter-category span.selected').size() > 0);
    if($('.dropdown.order_by').is(':visible')) {
      $('.dropdown.more > a').toggleClass('selected', $('.hookup,.access,.cell,.policy,.discount_clubs,.facilities,.recreation,.category_id').find('span.selected').size() > 0);
    } else {
      $('.dropdown.more > a').toggleClass('selected', $('.filter-more span.selected').size() > 0);
    }
  },
  updateCategoryIds: function() {
    $('#campground_search .input-category_id').remove();
    $.each($('.dropdown-menu.filter-category .selected,.category_id .selected'), function(i, el) {
      $('#campground_search').prepend($('<input>').attr('class', 'input-category_id dynamic').attr('name', 'filter_by_category_id[]').attr('type', 'hidden').val($(el).attr('data-value')));
    });
  },

  fetchResults: function(updateHistory = true, closeFilter = false, track = true) {
    if (multiMap) {
      $('#bounds').val('((' + multiMap.getBounds()._sw.lat + ', ' + multiMap.getBounds()._sw.lng + '), (' + multiMap.getBounds()._ne.lat + ', ' + multiMap.getBounds()._ne.lng + '))').trigger('change');
      $('#zoom_level').val(multiMap.getZoom());
      $('#center_of_map').val(JSON.stringify(multiMap.getCenter()));
    }
    var cleanData = $("#campground_search :input")
      .filter(
        function(index, element) {
          return $(element).attr('name') != 'utf8' && $(element).attr('name') != 'exclude_closed_temporarily_mobile' && $(element).val() != '';
        }
      )
      .serialize();

    $.getJSON('/campgrounds/search.json', cleanData, function(data) {
      if (window && window.campAnalytics && track) {
        window.campAnalytics.track('Places Searched', segmentPlaceDiscoveryProperties(data.results));
      }

      /* First, update URL */
      if(updateHistory) {
        var filteredData = $("#campground_search :input").filter(function(index, element) {
          return $(element).attr('name') != 'utf8' &&
            $(element).val() != '' &&
            $(element).attr('name') != 'state' &&
            $(element).attr('name') != 'category' &&
            $(element).attr('name') != 'city' &&
            $(element).attr('name') != 'place' &&
            $(element).attr('name') != 'center-popup' &&
            $(element).attr('name') != 'filter_by_admin_only[]' &&
            $(element).attr('name') != 'exclude_closed_temporarily' &&
            !($(element).attr('name') == 'price_range' && $(element).val() == '0-200');
        }).serialize();

        var elevationRange = minElevation + '-' + maxElevation;
        if($('input[name=elevation_range]').val() == elevationRange) {
          filteredData = filteredData.replace(/\&?elevation_range=[0-9-]*/, '');
        }

        // Update to state url if it exists
        var state = $('input[name=state]').val();
        if(state !== undefined && state.length > 0) {
          if(filteredData == 'filter_by_category_id%5B%5D=2') {
            // hardcode rv parks
            history.pushState(null, null, '/' + $('input[name=state]').val() + '/rv-parks');
          } else if(filteredData == 'filter_by_category_id%5B%5D=1') {
            // hardcode rv parks
            history.pushState(null, null, '/' + $('input[name=state]').val() + '/state-parks');
          } else if(filteredData == 'filter_by_category_id%5B%5D=9') {
            history.pushState(null, null, '/' + $('input[name=state]').val() + '/dump-stations');
          } else if($('input[name=price_range]').val() == '0-0' && $('input[name=city]').val() == '' && $('input[name=place]').val() == '') {
            if(filteredData.replace(/\&?price_range=0-0/, '') != '') {
              history.pushState(null, null, '/' + $('input[name=state]').val() + '/free-camping/map#' + filteredData.replace(/\&?price_range=0-0/, ''));
            } else {

              history.pushState(null, null, '/' + $('input[name=state]').val() + '/free-camping/map');
            }
          }
        } else {
          // Figure out here --> place, state, or city
          var baseUrl = '';
          if($('input[name=place]').val() != '') {
            baseUrl = $('input[name=place]').val() + '/map';
          } else if($('input[name=city]').val() != '') {
            baseUrl = $('input[name=state]').val() + '/' + $('input[name=city]').val();
          } else if($('input[name=state]').val() != '') {
            baseUrl = $('input[name=state]').val() + '/map';
          } else {
            baseUrl = 'campgrounds/map';
          }
          if(filteredData != '') {
            history.pushState(null, null, '/' + baseUrl + '#' + filteredData);
          } else {
            history.pushState(null, null, '/' + baseUrl);
          }
        }
      }
      if($('.dropdown.more').hasClass('open') && closeFilter) {
        $('.dropdown.more > a').trigger('click');
      }
      $('html,body').animate({ scrollTop: 0 }, 100);

      loadedCampgrounds = data.results;
      allCampgrounds.features = [];
      favoriteCampgrounds.features = [];
      $.each(data.results, function(i, b) {
        b.filtered_mapicon = b.mapicon;
        var newFeature = { 'type': 'Feature', 'geometry': { 'type': 'Point', 'coordinates': [b.longitude, b.latitude] }, 'properties': b };
        sortMap['campground-' + b.id] = i;
        allCampgrounds.features.push(newFeature);
        if(b.id && userFavorites[b.id.toString()]) {
          favoriteCampgrounds.features.push(newFeature);
        }
      }.bind(this));
      multiMap.getSource('campgrounds')
        && multiMap.getSource('campgrounds').setData(visibleFavoriteLayer());

      this.setState({ campgroundCount: data.results.length, noResultsClass: data.results.length == 0 ? '' : 'hide' });

      if(data['results'].length == 100) {
        $('#show-filters').html('See ' + data['results'].length + '+ Locations');
      } else if(data['results'].length == 1) {
        $('#show-filters').html('See 1 Location');
      } else {
        $('#show-filters').html('See ' + data['results'].length + ' Locations');
      }

    }.bind(this));
  },

  componentDidMount: function() {
    window.addEventListener('popstate', function(e) {
      $('.filter-category .checkbox').removeClass('selected');

      $.each(location.hash.replace(/^#/, '').split('&'), function(i, param) {
        var paramDetail = decodeURIComponent(param).split('=');

        if (paramDetail[0] == 'filter_by_category_id[]') {
          $.each(paramDetail[1].split(','), function (j, category_id) {
            $(".filter-category a[data-value='" + category_id + "'] .checkbox").addClass('selected');
          });
        }
      })
    });

    $('#search-filters,#mobile-filter').show();
    if($.cookie('user_id') !== undefined && $.cookie('user_id') !== '' && userIsSupporter) {
      $.ajax({ url: '/users/' + $.cookie('user_id') + '/favorites' }).done(function(data) {
        $.each(data.result.split(','), function(i, favorite_id) {
          userFavorites[favorite_id] = true;
        }.bind(this));
        $.each(loadedCampgrounds, function(i, b) {
          b.filtered_mapicon = b.mapicon;
          var newFeature = { 'type': 'Feature', 'geometry': { 'type': 'Point', 'coordinates': [b.longitude, b.latitude] }, 'properties': b };
          if(userFavorites[b.id.toString()]) {
            newFeature.properties.filtered_mapicon = 'favorites';
            favoriteCampgrounds.features.push(newFeature);
          }
        }.bind(this));
      }.bind(this));
    }

    $('div.dropdown-menu').on('click', function(e) {
      e.stopPropagation();
    });

    $('.dropdown').on('show.bs.dropdown', function(event) {
      if($(window).width() < 768) {
        $(this).find('> .dropdown-toggle > span > span').html('Close ').removeClass('more');
        $(this).find('> .dropdown-toggle > span.fa').removeClass('fa-chevron-down').addClass('fa-close');
        $('#map-wrapper').hide();
        $('a#display-toggle').css({ opacity: 0.0 });
      }
    });
    $('.dropdown').on('hide.bs.dropdown', function(event) {
      if($(window).width() < 768) {
        $(this).find('> .dropdown-toggle > span > span').html('More ').addClass('more');
        $(this).find('> .dropdown-toggle > span.fa').removeClass('fa-close').addClass('fa-chevron-down');
        if($('a#display-toggle').css('display') == 'block' && $('a#display-toggle').hasClass('with-map')) {
          $('#map-wrapper').show();
        }
        $('a#display-toggle').css({ opacity: 1.0 });
      }
    });

    $('.persist-free').on('click', function(e) {
      e.preventDefault();
      $(e.target).toggleClass('selected');
      if($(e.target).hasClass('selected')) {
        this.setPriceSliders([0,0]);
        $('span.pricing').html('FREE');
        $('.filter-price span.label').hide();
        $('input[name="price_range"]').val('0-0');
        $.cookie('persist_free', 1, { path: '/' });
        this.fetchResults(true, false);
        $('.persist-free').addClass('selected');
        $('#price-slider,#price-slider-mobile').attr('disabled', true);
      } else {
        this.setPriceSliders([0,200]);
        $('span.pricing').html('$0 - $200');
        $('.filter-price span.label').show();
        $('input[name="price_range"]').val('0-200');
        $.removeCookie('persist_free', { path: '/' });
        this.fetchResults(true, false);
        $('.persist-free').removeClass('selected');
        $('#price-slider,#price-slider-mobile').removeAttr('disabled');
      }
    }.bind(this));

    $('input[name="exclude_closed_temporarily"], input[name="exclude_closed_temporarily_mobile"]').on('click', function(e) {
      // Keep 'checked' state of both exclude_closed_temporarily checkboxes in sync
      if ($(e.target).is(':checked')) {
        $('input[name="exclude_closed_temporarily"], input[name="exclude_closed_temporarily_mobile"]').prop('checked', true);
        $.cookie('persist_exclude_closed_temporarily', 1, { path: '/' });
      } else {
        $('input[name="exclude_closed_temporarily"], input[name="exclude_closed_temporarily_mobile"]').prop('checked', false);
        $.removeCookie('persist_exclude_closed_temporarily', { path: '/' });
      }

      this.fetchResults(false);
    }.bind(this));

    $.each(['elevation-slider'], function(i, elId) {
      var ElevationSlider = document.getElementById(elId);
      noUiSlider.create(ElevationSlider, {
        start: [minElevation, maxElevation],
        behavior: 'tap',
        connect: true,
        range: {
          'min': [minElevation],
          'max': [maxElevation]
        },
        format: {
          from: function(value) {
            return parseInt(value);
          },
          to: function(value) {
            return parseInt(value);
          }
        }
      });
      if(!userIsSupporter) {
        ElevationSlider.setAttribute('disabled', true);
      }
      ElevationSlider.noUiSlider.on('slide', function(values, handle, unencoded, tap, positions) {
        $('span.elevation').html(values[0].toLocaleString() + ' - ' + values[1].toLocaleString());
        $('span.elevation-meters').html(parseInt(values[0]/3.828084).toLocaleString() + ' - ' + parseInt(values[1]/3.828084).toLocaleString());
      });
      ElevationSlider.noUiSlider.on('change', function(values, handle, unencoded, tap, positions) {
        $('input[name="elevation_range"]').val(values[0] + '-' + values[1]);
        this.fetchResults();
      }.bind(this));
    }.bind(this));

    $('.close-vector').on('click', function(e) {
      e.preventDefault();
      $('.dropdown.category').removeClass('open')
    })

    $.each(['price-slider', 'price-slider-mobile'], function(i, elId) {
      var priceSlider = document.getElementById(elId);
      noUiSlider.create(priceSlider, {
        start: [0, 200],
        behavior: 'tap',
        connect: true,
        range: {
          'min': [0],
          'max': [200],
        },
        format: {
          from: function(value) {
            return parseInt(value);
          },
          to: function(value) {
            return parseInt(value);
          }
        }
      });
      priceSlider.noUiSlider.on('slide', function(values, handle, unencoded, tap, positions) {
        if(values[0] == 0 && values[1] == 0) {
          $('span.pricing').html('FREE');
          $('.filter-price span.label').hide();
        } else {
          $('span.pricing').html('$' + values[0] + ' - $' + values[1]);
          $('.filter-price span.label').show();
        }
      });
      priceSlider.noUiSlider.on('change', function(values, handle, unencoded, tap, positions) {
        $('input[name="price_range"]').val(values[0] + '-' + values[1]);
        $('.dropdown.price > a').toggleClass('selected', values[0] != 0 || values[1] != 200);
        this.fetchResults();
      }.bind(this));
    }.bind(this));

    $('a.mobile-expand').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      var $filterNode = $(this).parent();
      $(this).parent().toggleClass('expanded');
      $(this).find('.fa').toggleClass('fa-chevron-down fa-chevron-up');
      $filterNode.find('span.show-more,span.hide-more').addClass('hidden');
      if($(this).parent().hasClass('expanded')) {
        $filterNode.find('.checkboxes').removeClass('hidden').css({ display: 'flex' });
      } else {
        $filterNode.find('.checkboxes').hide();
      }
    });
    $('span.show-more').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).parent().next('.checkboxes').toggleClass('hidden');
      $(this).addClass('hidden');
    });

    $('span.hide-more').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).parent().addClass('hidden');
      $(this).parent().prev('.checkboxes').find('.show-more').removeClass('hidden');
    });

    $('#clear-filters').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      location.hash = '';
      this.updateParamsFromHash();
      this.fetchResults(false, true);
    }.bind(this));

    $('#cancel-filters').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      location.hash = $('div.dropdown.more > a').attr('data-current-url');
      this.updateParamsFromHash();
      this.fetchResults(false, true);
    }.bind(this));

    $('#show-filters').on('click', function(e) {
      e.preventDefault();
      if($(window).width() < 768) {
        $('#map-wrapper,#display-toggle,.dropdown.more .dropdown-menu').toggle();
      } else {
        $('.dropdown.more > a').trigger('click');
      }
    });

    $('div.dropdown.more > a').on('click', function(e) {
      $(this).attr('data-current-url', location.hash);
    });

    $('.filter-order_by a').on('click', function(e) {
      e.preventDefault();
      var $link = $(e.target);
      if($link.hasClass('selected')) {
        $('input[name=order_by]').val('');
        $('.filter-order_by span.selected').removeClass('selected');
        $('.dropdown.order_by > a span:first').html('Sort By');
        $('.dropdown.order_by > a').removeClass('selected');
      } else {
        $('input[name=order_by]').val($link.attr('data-value'));
        $('.filter-order_by span.selected').removeClass('selected');
        $('.dropdown.order_by > a span:first').html($link.attr('data-selected'));
        $('.dropdown.order_by > a').addClass('selected');
        $('.filter-order_by a[data-value="' + $link.attr('data-value') + '"]').addClass('selected');
      }
      this.fetchResults();
    }.bind(this));


    $('.filter-category a').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      var $link = $(e.target);
      if($link.is('span')) {
        $link = $link.parent();
      }

      $link.toggleClass('selected');
      $link.find('.checkbox').toggleClass('selected')

      if($link.hasClass('rv') && !$link.hasClass('selected')) {
        $('input[name=category]').val('');
      }
      if($('#mobile-filters').is(':visible')) {
        $('.dropdown-menu.filter-category a[data-value="' + $link.attr('data-value') + '"]').toggleClass('selected', $link.hasClass('selected'));
      } else {
        $('#mobile-filters .filter-category a[data-value="' + $link.attr('data-value') + '"]').toggleClass('selected', $link.hasClass('selected'));
      }
      $('.dropdown.category > a').toggleClass('selected', $('.filter-category span.selected').size() > 0);


      if($link.hasClass('parking')) {
        if(!$link.hasClass('selected')) {
          $('input.parking-color-yellow').val('');
          $('input.parking-color-red').val('');
          $('.permissions-unknown').removeClass('toggle-true').addClass('toggle-false');
          $('.not-permitted').removeClass('toggle-true').addClass('toggle-false');
        }
      }
      this.updateCategoryIds();
      this.fetchResults();
    }.bind(this));

    $('a#display-toggle').on('click', function(e) {
      e.preventDefault();
      $('div#search-results,div#map-wrapper,div#carousel').toggle();
      if($('div#map-wrapper').css('display') == 'block') {
        $('#mobile-footer').hide();
        $('a#display-toggle').addClass('with-map').removeClass('with-listings');
        $('#search-filters').css({ opacity: 1.0 });
      } else {
        $('a#display-toggle').addClass('with-listings').removeClass('with-map');
        $('#mobile-footer').show();
        $('#search-filters').css({ opacity: 0 });
      }
      multiMap.resize();
    });

    // Kludgey to move admin_only up
    $('.admin_only').insertAfter($('#mobile-close-filters'));
    $('#mobile-close-filters').on('click', function(e) {
      e.preventDefault();
      $('#map-wrapper,#display-toggle,.dropdown.more .dropdown-menu').toggle();
    })

    // generates the filter_by necessary for the search filters
    $.each(['hookup', 'access', 'bookable', 'cell', 'policy', 'discount_clubs', 'facilities', 'recreation', 'category_id', 'admin_only'], function(i, className) {
      $('.' + className + ' span').not('.show-more,.hide-more,.member-signup,.supporter').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        var $link = $(e.target);
        if(className == 'access' && !$link.hasClass('selected')) {
          $('.filter-more .access span.selected').removeClass('selected');
        }
        $link.toggleClass('selected');
        if(className == 'category_id') {
          this.updateCategoryIds();
        } else {
          $('#campground_search .input-' + className).remove();
          $.each($('.' + className + ' span.selected'), function(i, el) {
            $('#campground_search').prepend($('<input>').attr('class', 'input-' + className + ' dynamic').attr('name', 'filter_by_' + className + '[]').attr('type', 'hidden').val($(el).attr('data-value')));
          });
        }

        if($('.dropdown.order_by').is(':visible')) {
          $('.dropdown.more > a').toggleClass('selected', $('.hookup,.bookable,.access,.cell,.policy,.discount_clubs,.facilities,.recreation,.category_id').find('span.selected').size() > 0);
        } else {
          $('.dropdown.more > a').toggleClass('selected', $('.filter-more span.selected').size() > 0);
        }
        this.fetchResults();
      }.bind(this));
    }.bind(this));

    $('.parking-info').on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
    });

    $(".orvp-close").on('click', function (e){
      e.preventDefault();
      e.stopPropagation();

      const orvpClose = $('.orvp-close svg')

      if(orvpClose.hasClass('fa-chevron-down')) {
        orvpClose.removeClass('fa-chevron-down').addClass('fa-chevron-up');
        $('.parking-info').slideDown();
      } else {
        orvpClose.removeClass('fa-chevron-up').addClass('fa-chevron-down');
        $('.parking-info').slideUp();
      }
    });

    $(".overnight-roadpass-pro-button").on('click', function (e){
      e.preventDefault();
      window.campAnalytics.trackSearchResultsMap('Overnight Parking Filter Upsell');
      window.location.href = '/users/authorization?context=Orvp+Filter';
    })

    $(".permissions-unknown").on('click', function (e){
      e.preventDefault();

      if (!userIsSupporter) {
        return false;
      }

      const permissionsUnknown = $(".permissions-unknown");

      if (permissionsUnknown.hasClass("toggle-false")) {
        permissionsUnknown.removeClass("toggle-false").addClass("toggle-true")
        $('input.parking-color-yellow').val('yellow')

        const parking = $('a.parking')
        if(!parking.hasClass('selected')) {
          parking.addClass('selected')
          $('a.parking span.checkbox').addClass('selected')
          this.updateCategoryIds();
        }

      }
      else {
        permissionsUnknown.removeClass("toggle-true").addClass("toggle-false")
        $('input.parking-color-yellow').val('')
      }

      this.fetchResults();
    }.bind(this));

    $(".not-permitted").on('click', function (e){
      e.preventDefault();

      if (!userIsSupporter) {
        return false;
      }

      const notPermitted = $(".not-permitted");

      if (notPermitted.hasClass("toggle-false")) {
        notPermitted.removeClass("toggle-false").addClass("toggle-true")
        $('input.parking-color-red').val('red');

        const parking = $('a.parking')
        if(!parking.hasClass('selected')) {
          parking.addClass('selected')
          $('a.parking span.checkbox').addClass('selected')
          this.updateCategoryIds();
        }
      }
      else {
        const parking = $('a.parking');
        const value = parking.hasClass('selected') ? 'true' : '';

        notPermitted.removeClass("toggle-true").addClass("toggle-false")
        $('input.parking-color-red').val('');
      }

      this.fetchResults()
    }.bind(this));

    if(!userIsSupporter) {
      $('.cell,.elevation').addClass('inactive');
      $('.cell div.checkboxes a').unbind('click').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();
      });
    }

    $(window).bind('popstate', function(e) {
      this.updateParamsFromHash();
      this.fetchResults(false);
    }.bind(this));

    // This must happen before map is loaded b/c bounds is set here
    this.updateParamsFromHash();

    // force a search if no search was triggered by the presence of URL params
    if (loadedCampgrounds.length === 0) {
      setTimeout(() => {this.fetchResults(false)}, 1)
    }

    this.setMapDimensions();
    $(window).bind('resize', function() {
      this.setMapDimensions();
    }.bind(this));

    var minZoomLimit = this.props.initialZoom - 3;
    if($('input[name=city]').val() == '' && $('input[name=place]').val() == '') {
      minZoomLimit = this.props.initialZoom - 1;
    }
    mapboxgl.accessToken = mapboxToken;
    multiMap = new mapboxgl.Map({
      container: 'multi-map',
      center: [this.props.mapCenterLng,this.props.mapCenterLat],
      zoom: this.props.initialZoom,
      style: mapboxStreetCellStyle,
      minZoom: minZoomLimit
    });
    if($('input#bounds').val() != '') {
      var existingBounds = eval($('input#bounds').val().replace(/\(/g, '[').replace(/\)/g, ']'));
      var filteredBounds = [
        [existingBounds[0][1], existingBounds[0][0]],
        [existingBounds[1][1], existingBounds[1][0]]
      ];
      multiMap.fitBounds(filteredBounds);
    }
    multiMap.dragRotate.disable();
    multiMap.touchZoomRotate.disableRotation();
    multiMap.setMaxBounds(this.props.bounds);

    multiMap.addControl(new mapboxgl.ScaleControl({ unit: 'imperial' }));

    var campendiumFullscreen = new mapboxgl.FullscreenControl();
    campendiumFullscreen._nativeOnClickFullscreen = campendiumFullscreen._onClickFullscreen;
    campendiumFullscreen._onClickFullscreen = function() {
      campendiumFullscreen._nativeOnClickFullscreen();
      if(navigator.userAgent.indexOf('AppleWebKit') != -1){
        setTimeout(function() {
          var $mapEl = $('#multi-map');
          if(campendiumFullscreen._fullscreen) {
            $mapEl.attr('data-stored-width', $mapEl.width()).attr('data-stored-height', $mapEl.height()).width('100%').height('100%');
          } else {
            $mapEl.width($mapEl.attr('data-stored-width')).height($mapEl.attr('data-stored-height'));
          }
          multiMap.resize();
        }, 200);
      }
    };

    if($(window).width() >= 768) {
      multiMap.addControl(campendiumFullscreen, 'bottom-right');
    }
    multiMap.addControl(new MapLayersControl(), 'top-right');

    if($(window).width() >= 768) {
      multiMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    }

    multiMap.addControl(new FavoritesControl(), 'bottom-right');
    if($(window).width() < 768) {
      multiMap.addControl(new FilterControl(), 'bottom-right');
    }

    multiMap.on('touchstart', function(e) {
      $('#cell-toggle:visible,#nonsupporter-toggle:visible,#land-toggle:visible').hide();
    });
    multiMap.on('dragstart', function(e) {
      $('#cell-toggle:visible,#nonsupporter-toggle:visible,#land-toggle:visible').hide();
    });

    $('.mapboxgl-ctrl-compass').hide();
    multiMap.on('dragend', function(e) {
      $('#bounds').val('((' + multiMap.getBounds()._sw.lat + ', ' + multiMap.getBounds()._sw.lng + '), (' + multiMap.getBounds()._ne.lat + ', ' + multiMap.getBounds()._ne.lng + '))').trigger('change');
      this.fetchResults(true, false, false);
    }.bind(this));
    multiMap.on('zoomend', function(e) {
      $('#bounds').val('((' + multiMap.getBounds()._sw.lat + ', ' + multiMap.getBounds()._sw.lng + '), (' + multiMap.getBounds()._ne.lat + ', ' + multiMap.getBounds()._ne.lng + '))').trigger('change');
      this.fetchResults(true, false, false);
    }.bind(this));
    $(".spinner").addClass('hide');
    $('#search-filters').removeClass('hide');

    multiMap.on('sourcedata', function(p) {
      var campgroundsSource = multiMap.getSource('campgrounds');
      if(multiMap.isSourceLoaded('campgrounds')) {
        var alreadyCounted = [];
        var unclusteredPoints = [];
        var renderedData = multiMap.querySourceFeatures('campgrounds');

        var campgroundIds = renderedData.map(function(e) { return e.properties.cluster ? 'cluster-' + e.id : 'campground-' + e.properties.id; }).join('-');
        if(this.state.campgroundIds != campgroundIds) {
          this.setState({ campgroundIds: campgroundIds });
          var campgroundCount = 0;

          $.each(renderedData, function(i, el) {
            if(el.properties.point_count !== undefined && !alreadyCounted['c-' + el.id]) {
              var clusterId = el.properties.cluster_id;
              var newCluster = { pointCount: el.properties.point_count, clusterId: clusterId, coordinates: el.geometry.coordinates, pointClass: 'small' };
              var cities = {};
              var states = {};
              var maxCityCount = 0;
              var maxStateCount = 0;
              var maxCity, maxState; var mapZoom = multiMap.getZoom();
              campgroundsSource.getClusterLeaves(clusterId, el.properties.point_count, 0, function (err, zoom) {
                $.each(zoom, function(i, el) {
                  if(cities[el.properties.city_state] === undefined) {
                    cities[el.properties.city_state] = 0;
                  }
                  cities[el.properties.city_state] += 1;
                  if(cities[el.properties.city_state] > maxCityCount) {
                    maxCityCount = cities[el.properties.city_state];
                    maxCity = el.properties.city_state;
                  }

                  if(states[el.properties.state] === undefined) {
                    states[el.properties.state] = 0;
                  }
                  states[el.properties.state] += 1;
                  if(states[el.properties.state] > maxStateCount) {
                    maxStateCount = cities[el.properties.state];
                    maxState = el.properties.state;
                  }
                });
                newCluster.title = (mapZoom < 5 ? maxState : maxCity).replace(/, $/, '');
                alreadyCounted['c-' + el.id] = true;
                var found = false;
                $.each(this.state.clusters, function(i, resultItem) {
                  if(resultItem.clusterId == newCluster.clusterId) {
                    found = true;
                  }
                });
                if(!found) {
                  var joined = this.state.clusters.concat(newCluster);
                  joined = joined.sort((a,b) => (a.pointCount > b.pointCount) ? 1 : ((b.pointCount > a.pointCount) ? -1 : 0));
                  this.setState({ clusters: joined.reverse() })
                }
              }.bind(this));
            } else if(el.properties.point_count === undefined && !alreadyCounted['u-' + el.properties.id]) {
              alreadyCounted['u-' + el.properties.id] = true;
              var bounds = multiMap.getBounds();
              var campgroundLat = parseFloat(el.properties.latitude);
              var campgroundLong = parseFloat(el.properties.longitude);
              var lng = (campgroundLong < bounds['_ne']['lng']) && (campgroundLong > bounds['_sw']['lng']);
              var lat = (campgroundLat < bounds['_ne']['lat']) && (campgroundLat > bounds['_sw']['lat']);
              if(lng && lat) {
                unclusteredPoints.push(el.properties);
              }
            }
          }.bind(this));

          unclusteredPoints.sort(function(a, b) {
            return sortMap['campground-' + a.id] - sortMap['campground-' + b.id];
          });

          // Updating here to show total campround count tied directly to what the map is showing,
          // and updated at the same time the campground list refreshes
          if(campgroundsSource._data.features && campgroundsSource._data.features.length > 0) {
            var campgroundCount = campgroundsSource._data.features.length;
          }

          this.setState({
            clusters: [],
            timesLoaded: this.state.timesLoaded + 1,
            campgrounds: unclusteredPoints,
            campgroundCount: campgroundCount
          });
        }
      }
    }.bind(this));

    sortMap = {};

    // inspect a cluster on click
    multiMap.on('click', 'clusters', function (e) {
      var features = multiMap.queryRenderedFeatures(e.point, { layers: ['clusters'] });
      var clusterId = features[0].properties.cluster_id;
      multiMap.getSource('campgrounds').getClusterExpansionZoom(clusterId, function (err, zoom) {
      if (err)
        return;

        multiMap.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom
        });
      });
    });

    multiMap.on('mouseenter', 'clusters', function () {
      multiMap.getCanvas().style.cursor = 'pointer';
    });
    multiMap.on('mouseleave', 'clusters', function () {
      multiMap.getCanvas().style.cursor = '';
    });
    multiMap.on('mouseenter', 'unclustered-point', function () {
      multiMap.getCanvas().style.cursor = 'pointer';
    });
    multiMap.on('mouseleave', 'unclustered-point', function () {
      multiMap.getCanvas().style.cursor = '';
    });

    multiMap.on('click', 'unclustered-point', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var item = e.features[0].properties;

      // Hide open filters
      $('.filter-toggle').hide();

      if(item.slug == null || item.slug === undefined) {
        // Item details not available, probably due to a large data query being limited.
        // Zoom in to trigger data load.
        multiMap.easeTo({
          center: e.features[0].geometry.coordinates,
          zoom: 8
        });
        return;
      }

      handleTrackEvent(item, 'Search Results Map')

      var contentString = `<a href="/${item.slug}?source=search-results-map" target="_blank"><div class="map-info-bubble">`;
      var bookable = item.booking_url_type !== "Not Bookable";

      if (item.sq_img !== null && item.sq_img != 'null') {
        contentString += '<div class="info-bubble-img">' +
          '<img src="' + item.sq_img + '" alt="' + item.title +'">' + '</div>'
      }
      contentString += '<div class="info-bubble-content">'
      if (bookable) {
        contentString +=  '<div class="booking-pill">Bookable</div>'
      }
      contentString += '<p class="info-bubble-title">' + item.title + '</p>' +
          '<p>' + item.city_state.replace(/, $/, '') + '</p>' +
          '<p>' + item.category_title + '</p>' +
          '<p>' +
            '<span class="stars">' +
              '<span class="stars' + item.stars + '"></span>' +
            '</span>' +
          '</p>' +
          '</div>';

        if (item.parking_color === 'red') {
          contentString += `<div class="overnight-parking-alert alert alert-danger" role="alert"><i class="fa-solid fa-octagon-exclamation"></i><div>Overnight parking not permitted</div></div>`;
        } else if (item.parking_color === 'yellow') {
          contentString += `<div class="overnight-parking-alert alert alert-warning" role="alert"><i class="fa-solid fa-triangle-exclamation"></i><div>Overnight parking permissions <br/> unknown</div></div>`;
        }

        contentString += '</div></a>';

        if (bookable) {
          contentString +=
          `<div class="btn-availability-map-wrapper"><a class="btn btn-large btn-warning btn-availability-cta" href="/${item.slug}" target="_blank">Check Availability</a></div>`
        }

      currentPopup = new mapboxgl.Popup({ offset: 10 })
        .setLngLat(coordinates)
        .setHTML(contentString)
        .addTo(multiMap);
    });

    multiMap.on('click', 'map-center', function (e) {
      var coordinates = e.features[0].geometry.coordinates.slice();
      var item = e.features[0].properties;
      var contentString = '<b>' + $('input[name=center-popup]').val() + '</b>';

      currentPopup = new mapboxgl.Popup({ offset: 30, closeButton: false })
        .setLngLat(coordinates)
        .setHTML(contentString)
        .addTo(multiMap);
    });

    const toolTipDiv = document.getElementById('tooltip-map-overlay')
    const spanIcon = document.getElementById('span-icon-closing')

    multiMap.on("load", () => {
      const mapLayerElement = document.getElementById('map-layers-toggle')
      const button = mapLayerElement.getElementsByTagName('button')[0]

      button.addEventListener('click', () => {
        window.campAnalytics.trackMap('Map Layers')
      })

      mapLayerElement.addEventListener('click', () => {
        toolTipDiv.style.display = 'none'
        const mapLayerElementChild = document.getElementById('map-layers-toggle-div')

        if (mapLayerElementChild.style.display === 'block') {
          mapLayerElement.parentNode.style.right = '-46px';
          button.style.display = 'none'
        } else {
          mapLayerElement.parentNode.style.right = '0px';
          button.style.display = 'block'
        }
      })

      if (!this.props.mapLayerSeen && !localStorage.getItem('map_layer_seen')) {
        toolTipDiv.style.display = 'block'
      }

      spanIcon.addEventListener('click', (e) => {
        e.preventDefault();
        toolTipDiv.style.display = 'none'
        localStorage.setItem('map_layer_seen', 'true')

        if($.cookie('user_id') !== undefined && $.cookie('user_id') !== '') {
          fetch('/users/update_map_layer_seen', { method: 'PUT', body: '', headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }}).then(() => {})
        }
      });
    });

    var geojsonCenter = { 'type': 'FeatureCollection', "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } }, 'features': [] };
    geojsonCenter.features.push({ 'type': 'Feature', 'geometry': { 'type': 'Point', 'coordinates': [this.props.mapCenterLng, this.props.mapCenterLat] }, 'properties': {} });

    // Reload data on styles.load
    multiMap.on('style.load', function() {

      var layerIds = _.map(multiMap.getStyle().layers, 'id');

      $.each(['LTE-verizon', 'LTE-att', 'LTE-tmobile', 'USFS', 'BLM', 'NPS', 'AZ-trust'], function(i, key) {
        if (_.includes(layerIds, key)) {
          multiMap.setLayoutProperty(
            key,
            'visibility',
            $('a#layer_' + key).hasClass('selected') ? 'visible' : 'none'
          );
        }
      });

      if($('input[name=city]').val() != '' || $('input[name=place]').val() != '') {
        multiMap.addSource('center', {
          type: "geojson",
          data: geojsonCenter
        });
        multiMap.addLayer({
          id: "map-center",
          type: "symbol",
          source: "center",
          layout: {
            'icon-image': 'center',
            'icon-offset': [0, -18],
            'icon-size': 0.8,
            'icon-allow-overlap': true
          }
        });
      }

      var cluster = true;
      if(document.location.hash != '' && document.location.hash.match(/cluster=false/)) {
        cluster = false;
      }

      /* Default allCampgrounds: All listings with regular map icons */
      allCampgrounds.features = [];
      $.each(loadedCampgrounds, function(i, b) {
        b.filtered_mapicon = b.mapicon;

        var newFeature = { 'type': 'Feature', 'geometry': { 'type': 'Point', 'coordinates': [b.longitude, b.latitude] }, 'properties': b };
        sortMap['campground-' + b.id] = i;
        allCampgrounds.features.push(newFeature);
      }.bind(this));

      multiMap.addSource('campgrounds', {
        type: "geojson",
        data: allCampgrounds,
        cluster: cluster,
        clusterMaxZoom: 6,
        clusterRadius: 125
      });

      multiMap.addLayer({
        id: "clusters",
        type: "circle",
        source: "campgrounds",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": "#f7a737",
          "circle-stroke-color": "#f7a737",
          "circle-radius": [
            "step",
            ["get", "point_count"],
            16, 100,
            25, 250,
            40
          ],
          'circle-opacity': [
            'step',
            ['get', 'point_count'],
            0.55, 100,
            0.70, 250,
            0.85
          ],
          'circle-stroke-width': [
            'step',
            ['get', 'point_count'],
            4, 100,
            5, 250,
            7
          ],
          'circle-stroke-opacity': [
            'step',
            ['get', 'point_count'],
            0.35, 100,
            0.50, 250,
            0.65
          ]
        }
      });

      multiMap.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "campgrounds",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12
        }
      });

      var filters = [
        'all',
        ["!has", "point_count"]
      ];
      if(userIsSupporter) {
        filters = [
          'all',
          ["!has", "point_count"]
        ];
      }
      multiMap.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "campgrounds",
        filter: filters,
        layout: {
          'icon-image': [
            'match',
            ['get', 'filtered_mapicon'],
            'parking', 'parking',
            'parkingalert', 'parkingalert',
            'rv', 'rv',
            'public', 'public',
            'dump', 'dump',
            'favorites', 'favorites',
            'bookablepublic', 'bookablepublic',
            'bookablerv', 'bookablerv',
            'public'
          ],
          'icon-offset': [0, -18],
          'icon-size': 0.8,
          'icon-allow-overlap': true
        }
      });
    }.bind(this));
  },

  render: function() {
    $('#search-results  .adthrive-ad').remove();
    const isLegacyUser = this.props.isLegacyUser
    const authorizePath = this.props.authorizePath

    return <div id='search-results'>
        { this.state.noResultsClass == 'hide' &&
          <div className='results-meta'>
            <h2>{ this.state.metaTitle }</h2>
            { this.state.campgroundCount != 0 &&
            <span>Showing { this.state.campgroundCount } locations</span>
            }
          </div>
        }
        { this.state.noResultsClass == '' &&
          <div className="results-meta no-results">
            <h3>No locations found!</h3>
            <p>Try refining your filters or entering a city and state into the search box.</p>
          </div>
        }
        {this.state.campgrounds.map(function(campgrounds, index) {
          return (
            <SearchResult
              isLegacyUser={isLegacyUser}
              authorizePath={authorizePath}
              key={index}
              data={campgrounds}
              result_index={{ index }}
              onTrackEvent={handleTrackEvent}
            />
          );
        })}
        {this.state.clusters.map(function(clusters, index) {
          return <ClusterResult key={index} data={clusters} result_index={{index}} />;
        })}
    </div>;
  }
});

export default Search
