import React from 'react'
import createReactClass from 'create-react-class'

var ClusterResult = createReactClass({
  handleClick: function() {
    if(currentPopup !== undefined) {
      currentPopup.remove();
    }
    var clusterId = this.props.data.clusterId;
    var coordinates = this.props.data.coordinates;
    multiMap.getSource('campgrounds').getClusterExpansionZoom(this.props.data.clusterId, function (err, zoom) {
      currentPopup.remove();
      multiMap.easeTo({
        center: coordinates,
        zoom: zoom
      });
    });
  },
  handleMouseEnter: function() {
    var renderedData = multiMap.querySourceFeatures('campgrounds');
    var campgroundId = this.props.data.id;
    var coordinates = this.props.data.coordinates;
    currentPopup = new mapboxgl.Popup({ offset: 10, closeButton: false })
      .setLngLat(coordinates)
      .setHTML(this.props.data.pointCount + ' locations near ' + this.props.data.title)
      .addTo(multiMap);
  },
  handleMouseLeave: function() {
    currentPopup.remove();
  },
  render: function() {
    return <div className="campground-row cluster">
      <div id={'search-result-adthrive-' + this.props.result_index.index}></div>
      <div className="campground-row-wrapper" onClick={this.handleClick} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <div className="campground-details">
          <h4>
            <span className={'point point-' + this.props.data.pointClass}><i className="fa fa-map-marker"></i></span>
            <span className="title">{this.props.data.title}</span>
          </h4>
          <span className="point-count">{this.props.data.pointCount} Nearby Locations</span>
        </div>
      </div>
    </div>;
  }
});

export default ClusterResult