// Do not remove this comment
// We're in the process of rebuilding the
// front end, migrating from the asset pipeline
// and legacy libraries like old bootstrap & jquery
// to newer tools like webpack & stimulus. This common
// file should only exist for the duration of the
// migration. Be mindful what you put here, and consider
// how it will eventually be integrated into the main
// application pack (or removed)

import 'stylesheets/common.scss'
import clamp from 'clamp-js'
import CampAnalytics from '../custom/camp_analytics'

const debounce = require('lodash.debounce');
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

// This is only here for the blog in production
// where the header is loaded async. It does not
// appear to effect normal usage in the Rails app.
ReactRailsUJS.mountComponents()

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount);

// Fix Common Nav scroll logic
let lastScrollTop = 0
let lastDownPos

const commonNavOnScroll = () => {
  const commonNavEl = document.getElementById('common-nav')
  let st = window.pageYOffset || document.documentElement.scrollTop

  if (st < 75) {
    commonNavEl.classList.remove('common-nav-up')
   } else if (st > lastScrollTop){
    commonNavEl.classList.add('common-nav-up')
    lastDownPos = st
   } else if (lastDownPos && lastDownPos - st > 50) {
    lastDownPos = null
    commonNavEl.classList.remove('common-nav-up')
   }
   lastScrollTop = st <= 0 ? 0 : st
}

document.addEventListener('click', e => {
  if(e.target.classList.contains('common-nav-near-me-btn')){
    navigator.geolocation.getCurrentPosition(position => {
      document.location = '/campgrounds/map?latitude=' + position.coords.latitude + '&longitude=' + position.coords.longitude + '&' + e.target.dataset.params
    }, _ => {
      alert('Sorry, we could not geolocate you.')
    })
  }
})

document.addEventListener('click', e => {
  if(e.target.closest("#mobile-common-nav-menu-btn")){
    const mobileCommonNav = document.getElementById('mobile-common-nav')
    mobileCommonNav.classList.toggle('opened')
    mobileCommonNav.classList.toggle('closed')
  }
})

const applyClamp = () => {
  const clampElements = document.querySelectorAll('.clamp-text')
  clampElements.forEach((element, index) => {
    const lines = element.dataset.clampLines
    clamp(element, { clamp: lines ? lines : 'auto' });
  })
}

const applyMenuActivation = () => {
  const menuItems = document.querySelector('.menu-items')
  let timer

  menuItems.addEventListener('mousemove', event => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      menuItems.classList.add('menu-items-open')
    }, 50)
  })

  menuItems.addEventListener('mouseleave', event => {
    clearTimeout(timer)
    menuItems.classList.remove('menu-items-open')
  })
}

window.applyClamp = applyClamp
window.applyMenuActivation = applyMenuActivation

document.addEventListener("DOMContentLoaded", () => {
  // Load common-nav scroll behavior on all but map page
  if (!document.body.className.match('campgrounds index')) {
    window.addEventListener("scroll", commonNavOnScroll, false)
  }

  // Blog context dependent code... :-(
  if (window.location.host.includes('go.campendium.com')) {
    var signOutLink = document.getElementById('sign-out')
    if (signOutLink) {
      signOutUrl = new URL(signOutLink.href)
      signOutUrl.searchParams.append('redirect_after_sign_out', window.location.href);
      signOutLink.setAttribute('href', signOutUrl)
    }

    document.addEventListener('click', e => {
      const el = e.target

      if (el.tagName == 'A' && el.href.includes('/user/sign_in') && el.className === 'login-link') {
        e.preventDefault()
        var url = new URL(el.href)
        url.searchParams.append('redirect_after', window.location.href);
        window.location = url
      }
    })
  } else {
    applyClamp()
    applyMenuActivation()
  }
})

const token = window.segmentToken
console.log(window.navigator.userAgent)
if (!token || typeof token === 'undefined') {
  console.warn("Could not initialize Segment analytics, must set window.segmentToken")
} else if (/bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(navigator.userAgent)) {
  console.warn("Not initializing analytics for bot")
} else {
  !function(){
    var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=token;analytics.SNIPPET_VERSION="4.15.2";
    analytics.load(token);
    analytics.page(null, null, {
      brand: 'Campendium',
      platform: 'Web'
    });
    window.campAnalytics = new CampAnalytics()
  }}();
}